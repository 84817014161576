@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer base {
  :root {
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }
 
  .dark {
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
  
  .__floater__open {
    pointer-events: auto;
  }

  *,
  *:focus {
    @apply outline-none;
  }

  html {
    font-size: 14px;
    scroll-behavior: smooth;

    @screen md {
      font-size: 16px;
    }

    @screen xl {
      font-size: 18px;
    }
  }

  body {
    margin: 0;
  }

  input {
    background-color: transparent;
  }

  // class to apply the `gradient` property inside the `<Text />` component
  .text-fill-color-transparent {
    -webkit-text-fill-color: transparent;
  }

  // hide number input indicators
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
  }

  ::selection {
    background-color: #0a93ec;
    color: #fff;
  }

  .dark {
    body {
      background-color: #110b1b;
    }

    ::-webkit-scrollbar-track {
      background-color: #110b1b;
    }
    ::-webkit-scrollbar-thumb {
      background: #202231;
    }
  }

  .light {
    body {
      background-color: #f0f6fa;
    }

    ::-webkit-scrollbar-track {
      background-color: #f0f6fa;
    }
    ::-webkit-scrollbar-thumb {
      background: #dce2e6;
    }
  }
}
